import { useRef, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'

import id from './id_1.jpg'
import './App.css'

const CANVAS_WIDTH = 640
const CANVAS_HEIGHT = 425

const SURNAME_X = 150
const SURNAME_Y = 188

const GIVEN_NAME_X = 150
const GIVEN_NAME_Y = 223

const MIDDLE_NAME_X = 150
const MIDDLE_NAME_Y = 259

const SEX_X = 150
const SEX_Y = 294

const DATE_OF_BIRTH_X = 150
const DATE_OF_BIRTH_Y = 310

const ADDRESS_X_1 = 56
const ADDRESS_Y_1 = 350

const ADDRESS_X_2 = 56
const ADDRESS_Y_2 = 368

const FILE_X_1 = 424
const FILE_Y_1 = 183
const FILE_WIDTH_1 = 180
const FILE_HEIGHT_1 = 213

const FILE_X_2 = 342
const FILE_Y_2 = 182
const FILE_WIDTH_2 = 76
const FILE_HEIGHT_2 = 90

function App() {
  const image = useRef(null)
  const canvas = useRef(null)
  const face = useRef(null)

  useEffect(() => {
    const img = image.current
    img.onload = () => {
      const ctx = canvas.current.getContext('2d')
      ctx.drawImage(img, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
    }
  }, [])

  const buildCanvas = (e) => {
    const ctx = canvas.current.getContext('2d')
    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height)
    ctx.drawImage(image.current, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
    ctx.font = "16px Courier"

    //build surname
    const surname = document.getElementById('surname')
    ctx.fillText(surname.value.toUpperCase(), SURNAME_X, SURNAME_Y)

    //build given name
    const givenName = document.getElementById('given-name')
    ctx.fillText(givenName.value.toUpperCase(), GIVEN_NAME_X, GIVEN_NAME_Y)

    //build middle name
    const middleName = document.getElementById('middle-name')
    ctx.fillText(middleName.value.toUpperCase(), MIDDLE_NAME_X, MIDDLE_NAME_Y)

    //build sex
    const sex = document.getElementById('sex')
    ctx.fillText(sex.value.toUpperCase(), SEX_X, SEX_Y)

    //build date of birth
    const dateOfBirth = document.getElementById('date-of-birth')
    //const formatValue = dateformat(dateOfBirth.value, 'mmm dd, yyyy')
    ctx.fillText(dateOfBirth.value, DATE_OF_BIRTH_X, DATE_OF_BIRTH_Y)

    //build address 1
    const address1 = document.getElementById('address1')
    ctx.fillText(address1.value.toUpperCase(), ADDRESS_X_1, ADDRESS_Y_1)

    //build address 2
    const address2 = document.getElementById('address2')
    ctx.fillText(address2.value.toUpperCase(), ADDRESS_X_2, ADDRESS_Y_2)

    //build file
    if (e) {
      const file = e.target.files[0]
      const FR = new FileReader()
      FR.addEventListener("load", (evt) => {
        const img = new Image()
        img.addEventListener("load", () => {
          document.getElementById('face').src = evt.target.result
          ctx.save()
          ctx.rotate(-1.8 * 0.01745)
          ctx.drawImage(face.current, FILE_X_1, FILE_Y_1, FILE_WIDTH_1, FILE_HEIGHT_1)
          ctx.drawImage(face.current, FILE_X_2, FILE_Y_2, FILE_WIDTH_2, FILE_HEIGHT_2)
          ctx.restore()
        })
        img.src = evt.target.result
      })
      FR.readAsDataURL(file)
    } else {
      ctx.save()
      ctx.rotate(-1.8 * 0.01745)
      ctx.drawImage(face.current, FILE_X_1, FILE_Y_1, FILE_WIDTH_1, FILE_HEIGHT_1)
      ctx.drawImage(face.current, FILE_X_2, FILE_Y_2, FILE_WIDTH_2, FILE_HEIGHT_2)
      ctx.restore()
    }
  }

  const downloadImage = () => {
    let link = document.createElement('a')
    link.download = 'id.png';
    link.href = document.getElementById('canvas').toDataURL()
    link.click()
  }

  return (
    <div className="App">
      <div className='form-default'>
        <Form className="form">
          <Form.Group className="mb-3">
            <Form.Label>Surname</Form.Label>
            <Form.Control type="text" id="surname" placeholder="Enter Surname" onChange={() => buildCanvas()} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>Given Name</Form.Label>
            <Form.Control type="text" id="given-name" placeholder="Enter Siven Name" onChange={() => buildCanvas()} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>Middle Name</Form.Label>
            <Form.Control type="text" id="middle-name" placeholder="Enter Surname" onChange={() => buildCanvas()} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>Sex</Form.Label>
            <Form.Control type="text" id="sex" placeholder="Enter Sex" onChange={() => buildCanvas()} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>Date Of Birth</Form.Label>
            <Form.Control type="text" id="date-of-birth" placeholder="Enter Date Of Birth" onChange={() => buildCanvas()} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>Address 1</Form.Label>
            <Form.Control type="text" id="address1" placeholder="Enter Address 1" onChange={() => buildCanvas()} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>Address 2</Form.Label>
            <Form.Control type="text" id="address2" placeholder="Enter Address 2" onChange={() => buildCanvas()} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>File</Form.Label>
            <Form.Control type="file" id="file" placeholder="Upload your file" onChange={(e) => buildCanvas(e)} />
          </Form.Group>
        </Form>
      </div>

      <div className="result">
        <canvas ref={canvas} width={640} height={425} id="canvas" />
        <br />
        <Button onClick={() => downloadImage()}>Download</Button>
      </div>

      <img
        ref={image}
        alt="id"
        src={id}
        width="640"
        height="425"
        className='hidden'
      />
      <img
        ref={face}
        id="face"
        alt="face"
        width="60"
        className='hidden'
      />
    </div>
  )
}

export default App
